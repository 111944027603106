body{
	font-family: $sans-serif;
	
	color: $black;
	background-color: $light-grey;
	font-size:15px;
    height: 100%;
}
.brand {
    display: inline-block;
    height: 70px;
    margin: 15px 0;
    img {
	    display: block;
	    max-height: 100%;
	    max-width: 100%;
	    margin: 0 auto;
	}
}
.brand.visible-xs-block{
		height:auto;margin:10px 0 0;
		img {
			margin:6px 15px;	height:45px;
		}
}
.container{
	background-color: transparent;
}
.bg-b{
	background-color: $brand-primary;
}
.bg-db{
	background-color: $brand-dark-primary;
}
.bg-w{
	background-color: $white;
}
.bg-g{
	background-color: $light-grey;
}

/*forms */
form h3{font-size:16px;color:$black;font-weight:600;}

/* SOCIALS */
.icon_twitter{color:#0099CC;}
.icon_facebook{color:#003399;}
.icon_youtube{color:#CC0000;}
.icon_instagram{color:#0066CC;}

 th{text-align:center;}
.rounded{
	-webkit-border-radius: 10px;-moz-border-radius: 10px;border-radius: 10px;-webkit-box-shadow: #B3B3B3 3px 3px 3px;-moz-box-shadow: #B3B3B3 3px 3px 3px; box-shadow: #B3B3B3 3px 3px 3px;border:1px solid #ebebeb;
	h4{font-weight:bold;font-size:24px;color: $black;}
}
.page_content.bg-w .rounded{box-shadow:none;-webkit-box-shadow:none;-moz-box-shadow:none;border:none;}
.col-md-3 .rounded h4{font-size:17px;}
.lead{font-size:17px;font-weight:600;}
.container-fluid.bg-w {
    margin: 20px 0;
}
.inner-padded{
	padding:10px 10px 30px;
	.inner-padded{padding-bottom:0px;}
}
.side-column{
	padding-left:30px;padding-right:0px;
	.rounded{margin-bottom:0em;}
}
.col-md-3.side-column .social-icons .fa-2x{font-size:1.1em;}
.sidebar-primary .main {
    float: left;
    width: 100% !important;
}
h1, .h1{
	font-size: 24px;color:$black;font-weight:700;
}
.h3, h3{font-size:21px;font-weight:600;}
main ul li {
    margin: 12px 0;
}
.content img {
    height: auto;
    max-width: 100%;
}
.content .gm-style img{
	max-width: none;
}
img.pull-left,
img[style*="float:left"],
a.pull-left img,
p.pull-left img,
div.pull-left img {
    padding: 15px 15px 15px 0;
}
img.pull-right,
img[style*="float:right"],
a.pull-right img,
p.pull-right img,
div.pull-right img {
    padding: 15px 0 15px 15px;
}

/* NAV */
.navbar{
	margin-bottom: 0;
	border:0px none;
	min-height: 0;
}
.primary-nav{position:relative;}

/* SUBNAV */
#subnav{background:#e2e2e2;margin:0;padding:0px;}


#subnav li{list-style:none}

#subnav li a {
    border-top: 1px solid #fff;
    color: $black;
	font-weight:600;
	font-size:1.1em;
    display: block;
    margin: 0;
    padding: 0.75em 1em;
    text-decoration: none;
}
#subnav li a:visited{}
#subnav li.current_page_item a{font-weight:bold;background:#f7f7f7;}
#subnav > li.current_page_item a:before  {
     color: $brand-primary;
    content: "";
    float: left;width:1.3em;
    font-family: 'FontAwesome';font-size:1.3em;
}
#subnav li a:hover{color:#007CBA;}
#subnav  li ul{padding:0px;margin:0px;}
#subnav  li ul li a{font-size:.95em;padding:0.25em 0 0.25em 3.2em;background-position:-442px -66px !important;font-weight:normal !important;}
#subnav  li ul li a:before{display:none;}

/* APEX NAVBAR */
#navbar-apex{position:absolute;top:0px;right:0px;z-index:500}
.affix #navbar-apex{display:none;}
.primary-nav .navbar-apex .nav {
    float: right;
    min-height: 28px;
}
.primary-nav .navbar-apex, .primary-nav .navbar-apex a{
	color: $black;
	font-size: 14px;
}
.primary-nav .navbar-apex > li{
	display: inline-block;
}
.primary-nav .navbar-apex > li > a{
    line-height: 100%;
    padding: 15px 0 12px 38px;
	position: relative;
	font-size: 0;
}

.primary-nav .navbar-apex > li > a > i {
    color: $black;
}
.primary-nav .navbar-apex > li > a:hover > i {
    color: $black;
}
.primary-nav .navbar-apex > li > a:focus, .navbar-apex li > a:hover {
    background-color: transparent;
    text-decoration: underline;
}
.primary-nav .navbar-apex > li.menu- > a {
    padding: 15px 10px 12px 0;
}
.primary-nav .navbar-apex > li.menu- > a i::before {
    font-size: 28px;
    line-height: 0;
    vertical-align: -8px;
}


#mc-embedded-subscribe-form{margin-top:-6px;}
#mc-embedded-subscribe-form button{background:#0E587C;border-color:#0E587C;color:$white;}

/* PRIMARY NAVBAR */
.navbar-nav > li.active > a {
    position:relative;
}
.primary-nav .navbar-nav.navbar-nav-main > li.active > a::after {
    bottom: 5px;
    content: "";
    display: block;
    left: 15px;
    position: absolute;
    width: calc(100% - 30px);
}


/* DROPDOWN MENU */
.navbar-primary .dropdown ul.dropdown-menu{
	border-left: 4px solid $brand-primary;
}



.navbar-primary {
    font-size: 18px;
    min-height: 105px;
    .nav.navbar-nav{
	    > li {
	    	> a{
				color: $black;
				font-weight:600;
			}
			> a:focus, > a:hover {
			    background-color: $brand-primary;
			    text-decoration: none;
			    color: $white;
			}
			.dropdown-menu{
				> li{
					a:focus, a:hover{
						background-color: $brand-primary;
    					color: $white;
					}
				}
			}
	    }
		
	}
}

.page-panel{
	text-align:center;
}
.intro-panel .info-panel.page-panel {
    text-align: center;
}
.intro-panel .rounded.bg-w {min-height:455px;}
.intro-panel.row {
    margin-bottom: 25px;
}
.page-child .intro-panel .info-panel.page-panel, .template-section-alt .intro-panel .info-panel.page-panel{
    height: unset;
    min-height: 110px;
    margin: 0 0 80px;
    padding-bottom: 40px;
    overflow: visible;
    position: relative;
}

/* COUNTDOWN */
#countdown {
    bottom: 0;
    left: 40px;
    position: absolute;
    width: 320px;color:#fff;
}
#countdown-number div{width:70px;height:70px;border-radius: 50%;background:rgba(255, 255, 255, 0.1);;display:inline-block;margin-right:15px;text-align:center;color:$white;font-size:26px;line-height:70px;}
#countdown-label div{width:70px;font-size:0.9em;text-align:center;display:inline-block;margin-right:15px;}
#countdown-label{margin-bottom:10px;}
/* MOBILE NAVBAR */
.navbar-collapse.in{
	margin-bottom:20px;
}
.navbar-toggle {
    border-radius: 0;
    border-color: transparent;
	background-color: $black;
    right: 0;
    position: absolute;
    top: 0;
    margin-top: 0;margin-right:0px;
    .icon-bar{
	    background-color: $white;
	    border-radius: 0;
	    display: block;
	    height: 3px;
	    width: 20px;
	}
}
.navbar-toggle:hover {
    .icon-bar{
	    background-color: $white;
	}
}
.navbar-header{
	position: static;
}

header {
    position: relative;
}

.page-header {
    border-bottom: 0 none;
    margin: 0;
    padding: 0;
}
.ss-container {
    border-top: 6px solid $brand-highlight;
    position: relative;
}

.header-contact{
	p{ 
		margin:0;
	}
	a, a:visited, a:hover{
		font-size:18.5px;
	}
	.tel {
	    font-size: 30px;
	    line-height: 100%;
	    padding-top: 4px;
	    margin-bottom: -2px;
	}
} 

/* CAROUSEL */
.carousel-caption {
    font-size: 15px;
    left: auto;
    max-width: 50%;
    position: absolute;
    right: 15px;
    text-align: left;
    top: 0;
	
}
.carousel-indicators {
    display:none;
}
.carousel-control{width:30px;}
.carousel-control.left,.carousel-control.right{background-image:none;}
.carousel-inner .item {
    /*height: 200px !important;*/
}

.flex-direction-nav .flex-prev,.flex-direction-nav:hover .flex-prev {
    left: 0px !important;
	opacity:0.8 !important;
}
.flex-direction-nav .flex-next,.flex-direction-nav:hover .flex-next {
    right: 0px !important;
	opacity:0.8 !important;
}
.col-md-9 .flex-viewport{margin:0 40px;}
#metaslider_187 .caption-wrap{position:relative;background:none;opacity:1;color:$black;text-align:center;}
#metaslider_70 li.ms-image {position:relative;}
#metaslider_70 li.ms-image a:before{
   
    content: "";
    display: block;
    font-family: "fontawesome";
    color:#fff;font-size:4em;opacity:0.5;
    left: 50%;;
    position: absolute;
    top: 50%;text-align:center;
    
    transform: translate(-50%, -50%);
}
#metaslider_70 li.ms-image:hover a:before{opacity:1;text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);}

.glyphicon-chevron-right::before{
	content: "";
	font-family: FontAwesome;
}
.glyphicon-chevron-left::before{
	content: "";
	font-family: FontAwesome;
}
.carousel-control{
	display:none;
} 
.page-banner {
    width: 100%;
    position: relative;
}
.banner.affix {
    min-height: 40px;
    z-index: 9999;
    width: 100%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.admin-bar .banner.affix {
	top: 0;
}
.banner.affix + .wrap {
    padding-top: 152px;
}

/* PAGE BANNER */

.page-header.with-banner{
	position:relative;
	margin:15px 0 25px;
	h1{
		position:absolute;
		bottom:0px;
		left:0px;
		background:$white;
		padding:10px 15px 0 0;
		margin-bottom:0px;
	}
	img{width:100%;}
}


/* FOOTER BANNER */
.footer-banner {
	position:relative;
	margin-top:1.5em;
	h4 {
		color: #fff;
		font-family: "Gloria Hallelujah", serif !important;
		position: absolute;
		text-align: center;
		top: 3vh;
		width: 100%;
		font-size: 1.7em;
	}
}
/* INFO PANELS */
.info-panel {
    background-color: #fff;
    border-radius: 20px;
    margin: 60px 0 0;
    padding: 15px 25px 40px;
    postition:relative;

    .img-responsive {
	    display: block;
	    margin-left: auto;
	    margin-right: auto;
	    max-width: 85%;
	}
	img, h2 {
	    margin-bottom: 25px;
	}
	.btn-panel {
	    display: block;
	    position: absolute;
	    width: 60px;
	    height: 60px;
	    left: 50%;
	    margin-left: -30px;
	    bottom: -30px;
	    border-radius: 30px;
	}
	.btn-panel::after {
	    bottom: 0;
	    color: #fff;
	    content: "";
	    font-family: FontAwesome;
	    font-size: 35px;
	    left: 0;
	    line-height: 55px;
	    padding: 1px 0 0 3px;
	    position: absolute;
	    right: 0;
	    text-align: center;
	    top: 0;
	}
}
main ul {
	list-style-type: none;
	position: relative;
	margin-left: 12px;
	padding-left: 0;
}
main li:before {
	content: "\2022";
	position: absolute;
	left: -12px;
	color: $brand-primary;
	margin-right: 5px;
}

/* NEWS PANELS */
.news-panel {
    display: block;
    margin-top: 20px;
    margin-bottom: 50px;
    > h2 {
	    margin-bottom: 40px;
	    text-align: center;
	    color: $black;
		font-size:1.3em;
		font-weight:bold;
	}
}
.news-img {
    background-position: center center;
    background-size: cover;
    display: block;
    margin: 30px 0 10px;
    min-height: 180px;
    width: 100%;
}
.news-item{
	a {
	    color: $brand-primary;
	}
	h4 > a {
	    color: $purple;
	}
}
.pull-left.news-list-img {
    max-height: 190px;
    padding-top: 5px;
    width: auto;
}
.section-panel{
    display: block;
    margin: 80px 0;
}

.modal-header {
    background-color: $brand-primary;
    color: $white;
}


.partner {
    margin-bottom: 15px;
}
.partner > a {
    display: block;
    height: 65px;
}
.partner img {
    height: auto;
    margin: 0 auto;
    max-height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
}
.content{
	margin-top: 75px;
}
.page-child .content {
    margin-top: 30px;
}
.content > div > p:first-child{
	font-size: 120%;
}

body.home .page_content{
	margin-top:2em;
}
.video-text-wrap {
    float: left;
    padding: 15px 15px 15px 0;
}
#banner-map{position:relative;background:none;height:400px;width:100%;}
.contact_form {
    background: #f7f7f7 none repeat scroll 0 0;
    margin-top: 15px;
    padding: 15px;
}
/* FOOTER */
footer {
    margin-top: 2.5em;
	.footer{
	    padding: 25px 0;
	    color: $white;
		font-size: 0.95em;
	    a{
		    color: $white;
		    margin-right: 10px;
		}
		a:hover, a:focus, a:active{
		    color: $white;
		}
		.fa {
		    color: $white;
		    margin: 0 7px 0 0;
		    vertical-align: baseline;
		}
	}
}
.gallery img {
    border: medium none !important;
    height: auto !important;
    max-width: 100%;
    padding: 0 7px;
}
.gallery .gallery-item {
    margin-top: 12px;
}
.gallery dl{
	margin: 0;
}

.slide-overlay {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    display: block;
    height: 550px;
    left: 0;
    overflow: visible;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
}

.page-child .col-md-6 > .embed-responsive, .page-child .col-md-6 > iframe {
    margin: 40px 0 25px;
}
.page-child .col-md-6{
	margin-bottom: 15px;
}
.banner-overlay {
    padding: 15px;
    text-align: center;
}
#map-container{
	height: 100%;
}
.mobile-contact-map{
	width: 100%;
	height: 400px;
}
.location-map{
	width: 100%;
	height: 500px;
}

.panel-heading{
	padding: 0;
}
.panel-heading .panel-title a, .panel-heading h3.panel-title{
    display: block;
    padding: 10px 15px;
    width: 100%;
}
.panel-title > .small, .panel-title > .small > a, .panel-title > a, .panel-title > small, .panel-title > small > a{
	color: $brand-primary;
}


.container-fluid.bg-b {
    position: relative;
}

.ninja-forms-all-fields-wrap {
    background-color: #dbdbdb;
    margin: 15px 0;
    padding: 15px;
}



.twitter-feed > div{
	padding: 20px 5px;
	color: $black;
}
.twitter > ul{
	list-style: none;
	margin: 0 0 15px 0;
	padding: 0;
}

.twitter > ul > li {
    background:#f7f7f7;
    color: $black;
    padding: 15px;
}
.twitter a{
	color: $black;
}
.twitter li a{
	color: $black;
}

.single-post .wrap header img {
    margin: 20px 0;
}

div.wpbs-calendar{
	border-radius: 5px;
}
.wpbs-container .wpbs-legend .wpbs-legend-item p{
	font-size: 12px;
	line-height: 15px;
}
.wpbs-container .wpbs-legend .wpbs-legend-color{
	height: 15px;
	width: 15px;
}
wpbs-form{
	padding: 0 5px;
}
.wpbs-widget .wpbs-legend {
    padding: 3px 0 0 !important;
}
.wpbs-form-form .wpbs-form-item label.wpbs-form-label {
    float: left !important;
    text-align: right;
    width: 84px;
    line-height: 1.2 !important;
}
.wpbs-form-form .wpbs-form-item input {
    float: right !important;
    margin-left: 15px;
    width: 150px !important;
}
.wpbs-form .wpbs-form-item .wpbs-form-submit{
	background-color: $brand-primary !important;
	margin-top: 0 !important;
    border: 0px none !important;
    color: $white !important;
}

/* HOMEPAGE */
.homepage-calls{margin:20px 0 10px;text-align:center;font-size:1.1em;font-weight:400;color:$black;
	img{
		text-align:center;margin:10px auto;	
	}
}

.calls-to-action{margin:20px 0;text-align:center;font-size:1.1em;font-weight:400;color:$black;
	img{
		text-align:center;margin:10px auto;	
	}
}

/* media queries */
@media (max-width: $screen-xs-min) {
	.carousel-caption .btn {
	    background-color: $brand-primary;
	    border: 0 none;
	    border-radius: 0;
	    bottom: -30px;
	    height: 30px;
	    left: 0;
	    padding: 4px 15px;
	    position: absolute;
	    text-align: left;
	    width: 100%;
	}
	.carousel-caption {
	    float: right;
	    margin-right: 15px;
	    margin-bottom: 30px;
	    position: static;
		display:none;
	}
	
	.video-text-wrap{max-width:100%;padding-right:0px;
		iframe{max-width:100%;height:auto;}
	}
	.rounded{border:none;border-radius:0px;box-shadow:none;}
	.rounded > .inner-padded{padding:15px 0 0;}
	.footer-banner h4{display:none;}
	.lead{font-size:16px;}
	body.home .page_content,footer{margin-top:0px;}
	#countdown{display:none;}
	.slide-overlay{
		bottom: 30px;
	}
	.news-panel{
		margin-top: 0;
		h2{
			margin-bottom: auto;
		}
	}
	.news-item p, .info-panel ul, .info-panel p{
		display: none;
	}
	.brand {
		-webkit-transition: all 0.35s;
		-moz-transition: all 0.35s;
    	transition: all 0.35s;
	}
	.affix .brand {
	    height: 40px;
		-webkit-transition: all 0.35s;
		-moz-transition: all 0.35s;
    	transition: all 0.35s;
	}
	.affix .brand.visible-xs-block{height:auto;}
	.banner.affix{visibility:none;}
	.intro-panel .info-panel{
		height: auto;
	}
	.navbar-primary .dropdown ul.dropdown-menu{
		border-radius: 0px;
	}
	.container.primary-nav {
	    height: 100%;
	    overflow-y: auto;
	}
}
@media (max-width: 767px) {
	.ss-container .page-banner{
		background-image: none !important;
	}
	.side-column{padding-left:0px;}
	.col-md-9 > .inner-padded{padding:0px;}
	.page-header.with-banner{margin:0 -15px 20px;
		h1{padding-left:15px;font-size:20px;}
	}

}
@media (min-width: $screen-sm-min) {
		.primary-nav .navbar-nav.navbar-nav-main > li.active > a::after {
	    bottom: 12px;
	}
	.page-banner {
	    background-position: center center;
	    background-repeat: no-repeat;
	    background-size: cover;
	    min-height: 380px;
	}
	.banner-overlay {
	    color: #fff;
	    line-height: 1.6;
	    max-width: 85%;
	    position: absolute;
	    left: 50%;
	    top: 20px;
	    width: 635px;
	    text-align:left;
	}
	.carousel-control{
	z-index: 999;
	display:block;
		.glyphicon-chevron-left, .icon-prev{
			left: 15px
		}
		.glyphicon-chevron-right, .icon-next{
			right: 15px
		}
		.glyphicon-chevron-left, .glyphicon-chevron-right, .icon-next, .icon-prev{
			font-size: 40px;
			top: 44%;
		}
	} 

	.affix .navbar-primary {
	    min-height: 50px;
		-webkit-transition: all 0.35s;
		-moz-transition: all 0.35s;
    	transition: all 0.35s;
	}
	.affix .brand {
	    display: inline-block;
	    height: auto;
	    margin: 5px 0 0 15px;
	}
	.affix .brand img {
	    display: block;
	    height: 50px;
	    width: auto;
		-webkit-transition: height 0.35s;
		-moz-transition: height 0.35s;
    	transition: height 0.35s;
	}
	.affix .navbar-primary .nav > li > a {
	    margin-top: 4px;
	}
	.dropdown-menu{
		border-top: 0px none;
		border-right: 0px none;
		border-bottom: 0px none;
		padding: 0;
	}
	.dropdown-menu > li:first-child > a {
	    padding-top: 8px;
	}
	.dropdown-menu > li:last-child > a {
	    padding-bottom: 8px;
	}
	.navbar-apex li.menu- > a{
		display:block;
	}
	.navbar-apex li > a{
		font-size: inherit;
	    padding: 5px;

	}
	.navbar-apex li.menu-main-sspdt-website >a{padding:11px 15px 0;}
	.brand {
	    height: 60px;
	    margin: 25px 0 0;
	    img {
	    	margin: 0;
			-webkit-transition: height 0.35s;
			-moz-transition: height 0.35s;
	    	transition: height 0.35s;
		}
	}
	.brand.visible-xs-block{height:40px;margin:10px 0 0;}
	
	.menu-newsletter-sign-up{
		padding-right: 5px;
	}
	.menu-newsletter-sign-up > a::after {
	    font-size: 18px;
	    left: -9px;
	    top: 4px;
	}

	.primary-nav .navbar-nav.navbar-nav-main > li > a {
	    padding: 16px 10px 18px;
	}
	.navbar-primary .nav {
	    float: right;
	    > li > a {
		    margin-top: 30px;
		}
	}
	.navbar-primary .dropdown ul.dropdown-menu{
		border-left-width: 2px;
	}
	.navbar-apex li.menu- > a i::before {
	    font-size: 18px;
	    vertical-align: -5px;
	}
	.primary-nav .navbar-apex > li.menu- > a {
	    padding: 0 0 0 10px;
	}
	.navbar-apex{
		height: 32px;
		overflow: hidden;
	}
	.navbar-apex .nav {
	    min-height: 0;
	}
	.admin-bar .banner.affix {
		top: auto;
	}
	.carousel-inner .item {
	    /*height: 397px !important;*/
	}
	.carousel-caption{
	    font-size: 19px;
	    top: 5px;
	    width: 600px;
	    max-width: 90%;
		text-shadow: 2px 2px 6px #000;
		h4 {font-family:'Gloria Hallelujah',$serif;font-size:1.8em;
		}
		.btn-default {
		    background-color: $brand-highlight;
		    border: medium none;
		    border-radius: 0px;
		    color: $white;
		    font-size: 15px;
		    font-weight: 700;
		    padding: 14px 35px;
		    margin-top: 20px;
		}
		.btn-default:hover, .btn-default:active:focus {
		    background-color: $brand-primary;
		    color: $white;
		}
	}
	.partner > a {
	    display: block;
	    height: 100px;
	}
	.news-img {
	    margin: 0 0 40px;
	    min-height: 240px;
	}
	.navbar-collapse.in {
	    margin-bottom: 0px;
	}
	.carousel-caption, .banner-overlay{
		left: 40px;
	}
	.banner-overlay{
		width: 450px;
	}
	
	.navbar-nav > li{display:inline-block;float:none;}
	.navbar-primary .nav{float:none;margin:0 auto;}
	.navbar-primary{text-align:center;}
	.navbar-primary .nav > li > a {
			    margin-top: 0px;
			}
	
}
@media (min-width: $screen-md-min) {
	
	.footer .col-md-6.end{
		text-align:right;
	}
	.intro-panel .info-panel {
	    height: 400px;
	    overflow: hidden;
	}
	.intro-panel .info-panel.page-panel {
	    height: 340px;
	}
	.vertical-align {
	  display: table;
	  table-layout: fixed;
	  height: 100%;
	  width: 100%;
	  margin-bottom: 15px;
	}
	.vertical-align > .col-md-6, .vertical-align > .col-md-4 {
	  display: table-cell;
	  float: none;
	  height: 100%;
	  vertical-align: middle;
	}
	.vertical-align .twitter-feed {
	    vertical-align: unset;
	}
	.primary-nav .navbar-nav.navbar-nav-main > li > a {
	    padding: 16px 13px 18px;
	}
	div.navbar-primary {
	    min-height: 100px;font-size:17px;
		ul.nav {
		    float: right;
		    > li > a {
			    margin-top: 46px;
				padding:16px 13px 18px
			}
		}
	}
	.brand {
	    height: 60px;
	}
	.carousel-caption, .banner-overlay{
		left: 40px;
	}
	.banner-overlay{
		width: 530px;
	}
	
	
	.navbar-nav > li{display:block !important;float:left !important;}
	div.navbar-primary .nav{float:right !important;margin:0 -15px 7.5px;}
	.navbar-primary{text-align:left !important;}
	
}
@media (min-width: $screen-lg-min) {
	.navbar-primary {
	    min-height: 100px;
		.nav {
		    float: right;
		    > li > a {
			    margin-top: 62px;
			}
		}
	}
	.col-md-3.side-column .social-icons .fa-2x{font-size:1.5em;}
	.primary-nav .navbar-nav.navbar-nav-main > li > a {
	    padding: 16px;
	}
	.carousel-caption, .banner-overlay{
		left: 40px;
	}
	.banner-overlay{
		width: 610px;
	}
	.slide-overlay{
		background-position: right bottom;
		background-repeat: no-repeat;
		background-size: auto auto;
	}
}
@media (min-width: 1440px) {
	.container {
	    /* width: 1400px; */
	}
	.ss-container {
	    .page-banner{
		    height:420px;
	    }
	}
}